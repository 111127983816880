import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
    <Layout sermonSrc="https://www.youtube.com/embed/nZGFf39QN6c">
        <SEO title="Preparation - Searching for the Perfect Christmas" />
    </Layout>
)

export default SermonPost
